@import "styles/variaveis.scss";

div.cadastros {
  min-height: 510px;
  h1.card-title {
    font-size: 35px;
    color: $blue;
    font-weight: 700;
  }
  table.cadastros {
    text-align: center;
    width: 100%;
    border: 1px solid #e9eaea;
    border-radius: 4px;
    font-size: 12px;
    thead {
      tr {
        color: $blackInput;
        text-align: center;
        border: 1px solid #e9eaea;
        background-color: #f5f6f8;
        th {
          padding: 15px;
          border-right: 1px solid #e9eaea;
        }
      }
    }
    tbody {
      tr {
        &.hovered {
          cursor: pointer;
          background-color: $blueBackgroundToastInfo;
        }
        color: $blackInput;
        border: 1px solid #e9eaea;
        td {
          padding: 15px;
          border-right: 1px solid #e9eaea;
          &.link {
            cursor: pointer;
            color: $blue;
            font-size: 12px;
            padding: 10px;
            text-align: center;
            &.red {
              color: $red;
            }
          }
        }
        &.guia,
        &.guia-alimento {
          font-weight: normal;
          border-top: 1px solid #e9eaea;
          td {
            border: none;
            span {
              font-weight: bold;
            }
          }
        }
        &.guia-alimento {
          border-top: hidden;
        }
      }
    }
  }
}

.modal-70w {
  max-width: 70% !important;
}

.results {
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  float: right;
}

.dre {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.endereco {
  padding-left: 68px !important;
  padding-right: 68px !important;
}

.demanda {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.detalhesDemanda {
  padding: 0px;
}

.detalhes {
  border: 1px solid #e9eaea;
  border-radius: "2px";
}

ol.breadcrumb {
  background-color: #F8F5F5;
  a {
    color: #035D96;
  }
}

li.breadcrumb-item.active::before{
  color: #035D96;
  text-align: center;
  font-family: "Font Awesome 5 Free";
  content: '\f138';
  font-weight: 600;
}
