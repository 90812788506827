div.conheca-a-rede-subtitulo {
  font-weight: bold;
  font-size: 12px;
  color: #7cd8f9;
}

div.header-acessibilidade {
  height: auto;
  background-color: #363636;
  color: #fff !important;
  font-size: 12px !important;
  line-height: 20px;
  li {
    a {
      text-decoration: none;
      color: #fff;
    }
  }
  li:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  span.span-accesskey {
    margin-left: 5px;
    padding: 1px 6px;
    background-color: #636464;
  }
  svg {
    margin-left: 5px;
  }
}

.logo-sme {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.button-to-top {
  img {
    box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, .4) !important;
    border-radius: 50%;
    z-index: 1;
    top: 80%;
    left: 90%;
    position: fixed;
    width: 75px;
  }
}