@import "styles/variaveis.scss";

div.textarea {
  font-size: 14px;
  height: 110px;
  span.required-asterisk {
    color: $red;
    padding-right: 5px;
  }
  textarea {
    font-size: 14px;
    color: $blackInput;
    &:focus {
      color: $blackInput;
    }
    &::placeholder {
      color: $grayPlaceholder;
    }
    &.invalid-field:not(:focus) {
      box-shadow: none;
      border-color: $red;
    }
    &:disabled {
      background-color: $grayDisabled;
    }
  }
}
