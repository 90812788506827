@import "styles/variaveis.scss";

.wrapperModal {
    border-bottom: 0px !important;
}

.modalText {
    text-align: center;
    font-size: 16px;
    line-height: 19px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    color: #42474A;
    letter-spacing: 0.01em;
}

.email {
    color: #2278FC;
}