@import "styles/variaveis.scss";

div.permissionamento {
  min-height: 400px;
  h1.card-title {
    font-size: 35px;
    color: $blue;
    font-weight: 700;
  }
  table.usuarios {
    font-size: 14px;
    width: 100%;
    border: 1px solid #e9eaea;
    border-radius: 4px;
    thead {
      tr {
        color: $blackInput;
        text-align: center;
        border: 1px solid #e9eaea;
        background-color: #f5f6f8;
        th {
          padding: 15px;
          border-right: 1px solid #e9eaea;
        }
      }
    }
    tbody {
      tr {
        color: $blackInput;
        border: 1px solid #e9eaea;
        td {
          padding: 15px;
          border-right: 1px solid #e9eaea;
          &.link {
            cursor: pointer;
            color: $blue;
            font-size: 12px;
            padding: 10px;
            text-align: center;
            &.red {
              color: $red;
            }
          }
        }
        &.guia,
        &.guia-alimento {
          font-weight: normal;
          border-top: 1px solid #e9eaea;
          td {
            border: none;
            span {
              font-weight: bold;
            }
          }
        }
        &.guia-alimento {
          border-top: hidden;
        }
      }
    }
  }
}

.modal-70w {
  max-width: 70% !important;
}

ol.breadcrumb {
  background-color: #F8F5F5;
  a {
    color: #035D96;
  }
}

li.breadcrumb-item.active::before{
  color: #035D96;
  text-align: center;
  font-family: "Font Awesome 5 Free";
  content: '\f138';
  font-weight: 600;
}

.legenda {
  font-size: 12px;
  font-style: italic;
}