@import "styles/variaveis.scss";

div.input {
  font-size: 14px;
  span.required-asterisk {
    color: $red;
    padding-right: 5px;
  }
  label {
    font-weight: 500;
    color: $gray;
  }
  .title {
    font-size: 20px;
    top: calc(50% - 34px / 2 - 679px);
    color: $corPretoTitulo;
    font-weight: bold;
  }
  input {
    font-size: 14px;
    color: $blackInput;
    height: 38px;
    font-weight: normal;
    &:focus {
      color: $blackInput;
    }
    &::placeholder {
      color: $grayPlaceholder;
    }
    &.invalid-field:not(:focus) {
      box-shadow: none;
      border-color: $red !important;
    }
    &:disabled {
      background-color: $grayDisabled;
    }
  }
  div {
    &.invalid-field:not(:focus) {
      box-shadow: none;
      border-color: $red !important;
      .p-fileupload-buttonbar {
        box-shadow: none;
        border-color: $red !important;
      }
      .p-fileupload-content {
        box-shadow: none;
        border-color: $red !important;
      }
    }
  }
}
