@import "styles/variaveis.scss";

#consulta-imovel {
    background-color: #F5F6F8;
    h4 {
        font-size: 24px;
        font-weight: bold !important;
        line-height: 30px;
      
        &.cor-azul {
          color: $corAzulPortal;
        }
      }
    .card-imovel {
      label {
        color: #686868;
        font-weight: 600;
      }
      p {
        font-size: 16px;
        font-weight: bold !important;
        line-height: 20px;
      
        &.cor-azul {
          color: $corAzulPortal;
        }
      }
      margin-bottom: 4%;
      border-radius: 5px;
      padding: 3%;
      .imovel-content{
        width: 80%;
        margin: 0 auto;
      }
      .botao-buscar{
        margin-top: 3%;
      }
    }
}
