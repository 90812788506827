@import "styles/variaveis.scss";

div.header-logo {
  display: block;
  height: 128px;
  background-color: $white;
  text-align: center;
  img {
    height: 128px;
  }
}
