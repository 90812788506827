@import "styles/variaveis.scss";
.modalTitle {
  color: $blue;
  font-weight: 700;
  font-size: 35px;
}

.title {
  color: $blue;
  font-weight: bold;
  font-size: 14px;
}

.observacoes{
  font-size: .9rem;
  font-weight: 700!important;
  color: #42474a !important;
  line-height: 1.5;
}

.enviarEmail {
  height: 30px !important;
}

.emailEnviado {
  font-size: 0.8rem !important;
}

.contador {
  float: right;
  font-weight: 600;
}

.anexoLabel {
  margin-top: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  line-height: 1.5;
  font-weight: 700!important;
  font-size: 14px;
  color: #42474a !important;
}