@import "styles/variaveis.scss";

div.cadastro-imovel {
  .container {
    h1 {
      color: $corAzulPortal;
      font-style: normal;
      font-weight: bold;
      line-height: 41px;
    }
    .title {
      font-size: 20px;
      top: calc(50% - 34px / 2 - 679px);
      color: $corPretoTitulo;
      font-weight: bold;
    }
    div.tabs {
      font-weight: bold;
      text-align: center;
      font-size: 24px;
      line-height: 28px;
      cursor: pointer;

      .tab {
        padding-bottom: 5px;
        border-radius: 1px;
        &.active {
          color: $corAzulPortal;
          border-bottom: 5px solid $corAzulPortal;
        }
        &.inactive {
          color: $corAzulDisabled;
          border-bottom: 5px solid $corAzulDisabled;
        }
        &.enabled {
          color: $blueEnabled;
          border-bottom: 5px solid $blueEnabled;
        }
      }
    }

    form {
      max-width: 800px;
      margin: 10px auto;
      border: 1px solid #ccc;
      padding: 20px;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
      border-radius: 3px;
      position: relative;
      background: #ffffff;
    }
  }
}

.p-dropdown {
  min-width: 0;
  border: 1px solid #ced4da !important;
  border-radius: 4px;
}

.form-check-label {
  font-size: 14px;
  color: $gray;
  display: inline;
}

.tela-final-cadastro-imovel {
  max-width: 800px;
  margin: 10px auto;
  height: max-content;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  position: relative;
  background: #ffffff;
  margin-top: 150px;
  bottom: 20px;

  h5 {
    color: $corAzulPortal;
    font-style: normal;
    font-weight: bold;
    line-height: 41px;
  }
}

.protocolo-box {
  background: #ebebeb;
  padding: 10px;
  width: 180px;
  height: 55px;
  text-align: center;
  font-weight: bold;
  font-size: 25px;
}
