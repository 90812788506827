@import "styles/variaveis.scss";

.input-file {
  input.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  .file-div {
    .file-name {
      padding-top: 5px;
      font-size: 10px;
      &:hover {
        color: $blue;
        text-decoration: underline;
      }
    }
    .exclude-icon {
      i {
        &:hover {
          color: $red;
        }
      }
    }
  }
}
