// Cores

$corBranco: #ffffff;

$corCinzaClaro: #f3f3f3;
$corCinzaClaroSelect: #a4a4a4;
$corCinzaEscuro: #c4c4c4;
$corCinzaHome: #5c6f78;
$corCinzaBuscaOff: #ced4da;
$corCinzaRodape: #5a5a5a;
$corCinzaToggle: #565656;
$corPretoTitulo: #42474a;

$corPretoPortal: #42474a;

$corVerdeClaro: #afd367;
$corVerdeEscuro: #4e9c45;
$corVerdeBotaoHome: #afce68;

$corAzulFiltro: #00a6e2;
$corAzulBackgroundTable: #d7f0ff;
$corAzulBuscaOn: #80bdff;
$corAzulPortal: #196cbd;
$corAzulDisabled: rgba(25, 108, 189, 0.5);
$black: #000000;
$blackInput: #42474a;
$blackSubtle: #333333;
$blue: #035d96;
$blueEnabled: rgba(25, 108, 189, 0.5);
$blueBackgroundToastInfo: #d1ecf1;
$blueBackgroundToastPrimary: #cce5fd;
$blueButtonBackground: #064f79;
$blueButtonFont: #086397;
$blueCheckAndRadio: #007bff;
$blueIcon: #334d6e;
$blueSelected: #f2fbfe;
$blueToastInfo: #0c5460;
$bordeaux: #864241;
$gray: #686868;
$grayBackground: #fafafa;
$grayCard: #eeeeee;
$grayDisabled: #dadada;
$grayHeader: #929394;
$grayIcon: #c2cfe0;
$grayInputBackground: #e8e8e8;
$grayLight: #a8a8a8;
$grayPlaceholder: #a4a4a4;
$green: #198459;
$greenBackgroundToastSuccess: #d4edda;
$greenButtonHover: #0c6b45;
$greenHover: #00c855;
$greenPanel: #297805;
$greenToastSuccess: #155724;
$orange: #d06d12;
$placeholder: #ced4da;
$purple: #9b51e0;
$red: #a50e05;
$redAlert: #e21017;
$redToastError: #721c24;
$redBackgroundToastError: #f8d7da;
$yellow: #fdc500;
$yellowBackgroundToastWarning: #fff3cd;
$yellowToastWarning: #856404;
$white: #ffffff;
$whiteBackground: #f8f5f5;

/* SME */
$red: #a50e05;
$grayPlaceholder: $corCinzaClaroSelect;
$gray: #686868;
$blackInput: $corPretoPortal;
$grayDisabled: #dadada;

/* Toasts */
$blueBackgroundToastInfo: #d1ecf1;
$blueBackgroundToastPrimary: #cce5fd;
$blueEnabled: #196cbd;
$blueToastInfo: #0c5460;
$greenBackgroundToastSuccess: #d4edda;
$greenToastSuccess: #155724;
$redToastError: #721c24;
$redBackgroundToastError: #f8d7da;
$yellowBackgroundToastWarning: #fff3cd;
$yellowToastWarning: #856404;

// Fontes

@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700");

@mixin roboto-sans {
  font-family: "Roboto", sans-serif !important;
}
